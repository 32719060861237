/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
    Box,
} from "@chakra-ui/react";
import ColumnsTable from "views/admin/decks/components/ColumnsTable";
import ColumnsTableFlashCards from "views/admin/decks/components/ColumnsTableFlashCards";
import ColumnsTableSubDecks from "views/admin/decks/components/ColumnsTableSubDecks";
import {
  columnsDataColumns,
} from "views/admin/decks/variables/columnsData";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListDecks } from "services/decks";
import { ListFlashcards } from "services/flashcards";
import { ListSubdecks } from "services/subdecks";

export default function Users() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [totalItems, setTotalItems] = useState(0);

    const [listSubDecks, setListSubDecks] = useState([]);
    const [totalPagesSubDecks, setTotalPagesSubDecks] = useState(1);
    const [currentPageSubDecks, setCurrentPageSubDecks] = useState(1);
    const [searchTxtSubDecks, setSearchTxtSubDecks] = useState('');
    const [totalItemsSubDecks, setTotalItemsSubDecks] = useState(0);

    const [listFlash, setListFlash] = useState([]);
    const [totalPagesFlash, setTotalPagesFlash] = useState(1);
    const [currentPageFlash, setCurrentPageFlash] = useState(1);
    const [searchTxtFlash, setSearchTxtFlash] = useState('');
    const [totalItemsFlash, setTotalItemsFlash] = useState(0);
    const [isLoading, setIsLoading] = useState(null);

    const [idDeck, setIdDeck] = useState(null);
    const [idSubDeck, setIdSubDeck] = useState(null);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    const getList = async () => {
        setIsLoading(true);
        const response = await ListDecks(null, searchTxt, currentPage, user?.token);
        setIsLoading(false);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
        setTotalItems(response?.result?.amount || 0);
    };

    useEffect(() => {
        if (idDeck) {
            const timeOutId = setTimeout(() => {
                getListSubDecks();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPageSubDecks, searchTxtSubDecks, idDeck]);

    const getListSubDecks = async () => {
        setIsLoading(true);
        const response = await ListSubdecks(idDeck.id, null, searchTxtSubDecks, currentPageSubDecks, user?.token);
        setIsLoading(false);
        setListSubDecks(response?.result?.list || []);
        setTotalPagesSubDecks(response?.result?.qtdPages || 1);
        setTotalItemsSubDecks(response?.result?.amount || 0);
    };

    useEffect(() => {
        if (idSubDeck) {
            const timeOutId = setTimeout(() => {
                getListFlashCard();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPageFlash, searchTxtFlash, idSubDeck]);

    const getListFlashCard = async () => {
        setIsLoading(true);
        const response = await ListFlashcards(idSubDeck.id, null, searchTxtFlash, currentPageFlash, user?.token);
        setIsLoading(false);
        setListFlash(response?.result?.list || []);
        setTotalPagesFlash(response?.result?.qtdPages || 1);
        setTotalItemsFlash(response?.result?.amount || 0);
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {!idDeck && !idSubDeck ? (
            <ColumnsTable
                tableData={list}
                getList={getList}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchTxt={searchTxt}
                setSearchTxt={setSearchTxt}
                setIdDeck={setIdDeck}
                totalItems={totalItems}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        ) : null}
        {idDeck && !idSubDeck ? (
            <ColumnsTableSubDecks
                tableData={listSubDecks}
                getList={getListSubDecks}
                totalPages={totalPagesSubDecks}
                currentPage={currentPageSubDecks}
                setCurrentPage={setCurrentPageSubDecks}
                searchTxt={searchTxtSubDecks}
                setSearchTxt={setSearchTxtSubDecks}
                idDeck={idDeck}
                setIdDeck={setIdDeck}
                setIdSubDeck={setIdSubDeck}
                totalItems={totalItemsSubDecks}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        ) : null}
        {idDeck && idSubDeck ? (
            <ColumnsTableFlashCards
                columnsData={columnsDataColumns}
                tableData={listFlash}
                getList={getListFlashCard}
                totalPages={totalPagesFlash}
                currentPage={currentPageFlash}
                setCurrentPage={setCurrentPageFlash}
                searchTxt={searchTxtFlash}
                setSearchTxt={setSearchTxtFlash}
                idDeck={idDeck}
                idSubDeck={idSubDeck}
                setIdSubDeck={setIdSubDeck}
                setIdDeck={setIdDeck}
                totalItems={totalItemsFlash}
                isLoading={isLoading}
            />
        ) : null}
    </Box>
  );
}
