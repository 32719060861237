/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
// Assets
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { UploadFile } from "services/upload";

function Dropzone(props) {
    const { content, onImageSelect, accept, multiple, styleNone, loading, setLoading, ...rest } = props;

    const onDrop = useCallback(async (acceptedFiles) => {
        if (loading) {
          alert('Já existe um arquivo sendo carregado, tente novamente mais tarde.');
          return null;
        }
        const uploadPromises = acceptedFiles.map(async (file) => {
            const responseLogo = await UploadFile(file);
            return responseLogo.result || '';
        });

        try {
            if (setLoading) {
              setLoading(true);
            }
            const results = await Promise.all(uploadPromises);
            onImageSelect(results);
        } catch (error) {
            if (setLoading) {
              setLoading(false);
            }
            console.error('Erro ao carregar os arquivos:', error);
        }
    }, [onImageSelect]);

    // const handleDrop = async (acceptedFiles) => {
    //     const file = acceptedFiles[0];
    //     const reader = new FileReader();

    //     reader.onload = () => {
    //         const base64String = reader.result;
    //         console.log('base64String', base64String);
    //         onImageSelect(base64String);
    //     };

    //     reader.onabort = () => console.log("Leitura do arquivo abortada");
    //     reader.onerror = () => console.log("Erro ao ler arquivo");

    //     reader.readAsDataURL(file);
    // };
  

  const { getRootProps, getInputProps } = useDropzone({ accept, onDrop, multiple });
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <Flex
      align='center'
      justify='center'
      bg={styleNone ? 'transparent' : bg}
      border={styleNone ? 'none' : '1px dashed'}
      borderColor={styleNone ? 'transparent' : borderColor}
      borderRadius='16px'
      w='100%'
      h='max-content'
      minH='100%'
      cursor='pointer'
      {...getRootProps({ className: "dropzone" })}
      {...rest}>
      <Input variant='main' {...getInputProps()} />
      <Button variant='no-effects'>{content}</Button>
    </Flex>
  );
}

export default Dropzone;
