export const columnsDataColumns = [
    {
        Header: "NOME",
        accessor: "name",
    },
    {
        Header: "CRIADO EM",
        accessor: "created_at",
    },
    {
        Header: "AÇÕES",
        accessor: "actions",
    },
];
