import { api } from './api';

export async function ListSistemasDeQuestoes(idSistemaDeQuestao, searchTxt, page, token) {
    try {
        let baseURL = `/sistemasDeQuestoes?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idSistemaDeQuestao) {
            baseURL = `${baseURL}&idSistemaDeQuestao=${idSistemaDeQuestao}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateSistemasDeQuestoes(name, token) {
    try {
        const response = await api.post(`/sistemasDeQuestoes`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditSistemasDeQuestoes(name, idSistemaDeQuestao, token) {
    try {
        const response = await api.put(`/sistemasDeQuestoes?idSistemaDeQuestao=${idSistemaDeQuestao}`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteSistemasDeQuestoes(idSistemaDeQuestao, token) {
    try {
        const response = await api.delete(`/sistemasDeQuestoes?idSistemaDeQuestao=${idSistemaDeQuestao}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
