import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";

const Pagination = ({ currentPage, totalPages, onPageChange, limit, totalItems }) => {
  const MAX_VISIBLE_PAGES = limit || 5;
  const pages = [];

  const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
  const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <Flex justify="space-around" align="center" mt={4} pt="50px">
      <Text fontSize='xs' fontWeight='400' color="transparent">{`Total de itens: ${totalItems}`}</Text>
      <Flex justify="center" align="center">
        <Button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          mr={2}
          // colorScheme="blue"
          variant="brand"
        >
          Anterior
        </Button>
        {pages.map((page) => (
          <Button
            key={page}
            variant={currentPage === page ? "brand" : "outline"}
            colorScheme={currentPage === page ? "" : "gray"}
            onClick={() => onPageChange(page)}
            size="sm"
            mx={1}
          >
            {page}
          </Button>
        ))}
        <Button
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
          ml={2}
          // colorScheme="blue"
          variant="brand"
        >
          Próxima
        </Button>
      </Flex>
      <Text color='secondaryGray.700' fontSize='medium' fontWeight='400'>{`Total de itens: ${totalItems || '-'}`}</Text>
    </Flex>
  );
};

export default Pagination;
