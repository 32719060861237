import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    Grid,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Dropzone from "components/dropzone";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { DeleteFlashcards } from "services/flashcards";
import { EditFlashcards } from "services/flashcards";
import { CreateFlashcards } from "services/flashcards";
import { FaFileAlt, FaFileAudio, FaFileImage, FaFilePdf, FaFileVideo } from "react-icons/fa";
import { getNameWithExtensionFromUrl } from "utils/masks";
import { MdClose, MdArrowCircleLeft } from "react-icons/md";
import NavigationLinks from "../../../../components/NavigationLinks";

export default function ColumnsTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt, idDeck, idSubDeck, setIdSubDeck, setIdDeck, totalItems, isLoading } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 10;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bg = useColorModeValue("gray.100", "navy.700");
    const borderColorArchive = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
    const brandStars = useColorModeValue("main.200", "main.300");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [frontFlashCard, setFrontFlashCard] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [frontFlashCardComplet, setFrontFlashCardComplet] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [backFlashCard, setBackFlashCard] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [backFlashCardComplet, setBackFlashCardComplet] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [esquemaFlashCard, setEsquemaFlashCard] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [dicaFlashCard, setDicaFlashCard] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [comentariosFlashCard, setComentariosFlashCard] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(true);
    const [errorPergunta, setErrorPergunta] = useState('');

    // Configuração da toolbar com todas as opções
    const modules = {
        toolbar: [
            [{ 'header': '1'}, { 'header': '2'}, { 'header': [3, 4, 5, 6] }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            [{ 'align': [] }],
            ['clean']
        ]
    };
    
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'align'
    ];

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        setFrontFlashCard('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setFrontFlashCardComplet('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setBackFlashCard('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setBackFlashCardComplet('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setEsquemaFlashCard('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setDicaFlashCard('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setComentariosFlashCard('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
        setType(true);
        setErrorPergunta('');
    };

    const getFileIcon = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        switch (extension) {
          case 'pdf':
            return FaFilePdf;
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
          case 'bmp':
          case 'svg':
            return FaFileImage;
          case 'mp3':
          case 'wav':
          case 'ogg':
            return FaFileAudio;
          case 'mp4':
          case 'avi':
          case 'mov':
          case 'wmv':
          case 'flv':
          case 'mkv':
            return FaFileVideo;
          default:
            return FaFileAlt;
        }
    };

    const validationSchema = Yup.object().shape({
        verso: Yup.string().notRequired(),
        attachments: Yup.array().notRequired(),
    });

    const onClickDuplicate = async (item) => {
      setLoading(true);
      try {
          const response = await CreateFlashcards(
              item.type,
              idDeck.id,
              idSubDeck.id,
              item.frente,
              item.verso,
              item.dica,
              item.comentarios,
              item.esquema,
              item.attachments ? JSON.parse(item.attachments): null,
              undefined,
              user?.token,
          );
          setLoading(false);
          if (response?.status === 200 || response?.status === 201) {
              onClose();
              getList();
          } else {
              alert(response?.message || "Erro ao duplicar flash card.");
          }
      } catch (err) {
        setLoading(false);
        alert(err?.message || "Erro ao duplicar flash card");
      }
    };

    const onClickEdit = (item) => {
        setInfoRow(item);
        setFrontFlashCard(item.frente);
        setFrontFlashCardComplet(item.frente);
        setBackFlashCard(item.verso);
        setBackFlashCardComplet(item.verso);
        setEsquemaFlashCard(item.esquema);
        setDicaFlashCard(item.dica);
        setComentariosFlashCard(item.comentarios);
        setIsOpen(true);
        setType(item.type === '1');
    };

    const onClickDelete = async (item) => {
        await DeleteFlashcards(item.id, user?.token);
        getList();
    };

    const handleSave = (textEditor) => {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = textEditor;
    
      const textContent = tempElement.textContent || tempElement.innerText || '';
      const trimmedText = textContent.trim();
    
      if (trimmedText) {
        console.log('Há conteúdo de texto visível');
        return true;
      }
    
      const hasOtherContent = tempElement.querySelector('img, video, a, iframe') !== null;
    
      if (hasOtherContent) {
        console.log('Há conteúdo de mídia visível');
        return true;
      }
    
      console.log('Conteúdo vazio');
      return false;
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <NavigationLinks
            links={[idDeck.name, idSubDeck.name, 'FlashCards']}
            onClickLink={(link) => {
                if (link === idSubDeck.name) {
                    setIdSubDeck(null);
                } else {
                    setIdDeck(null);
                    setIdSubDeck(null);
                }
            }}
        />
        <Flex justifyContent="space-between" flexWrap="wrap" padding="0px 20px 0px 20px" mb="20px" gap="20px">
            <Flex>
                <SearchBar
                    placeholder="Buscar flashcard"
                    value={searchTxt}
                    setValue={setSearchTxt}
                />
            </Flex>

            <Flex gap="10px" alignItems="center">
              <Dropzone
                  minH="60px"
                  w="100%"
                  borderRadius="10px"
                  loading={loading}
                  setLoading={setLoading}
                  onImageSelect={async (files) => {
                    const acceptedFiles = files[0].endsWith('.apkg');
                    if (acceptedFiles) {
                      try {
                          const response = await CreateFlashcards(
                              undefined,
                              idDeck.id,
                              idSubDeck.id,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              files[0],
                              user?.token,
                          );
                          setLoading(false);
                          if (response?.status === 200 || response?.status === 201) {
                              getList();
                          } else {
                            alert(response?.message || '');
                          }
                      } catch (err) {
                        setLoading(false);
                      }
                    } else {
                        setLoading(false);
                        alert("Por favor, selecione apenas arquivo .apkg");
                    }
                  }}
                  styleNone
                  content={
                    <Button w='250px' h='44px' variant='brand' isLoading={loading}>
                      Inclusão em massa (.apkg)
                    </Button>
                  }
              />
              <Button onClick={() => setIsOpen(true)} w='250px' h='44px' variant='brand'>
                  Cadastrar flashcard
              </Button>
            </Flex>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="full">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                  <Flex>
                    <Icon cursor="pointer" onClick={onClose} as={MdArrowCircleLeft} w='48px' h='auto' color='main.300' mr="20px" />
                    <Flex direction="column">
                      {infoRow ? 'Editar flashcard' : 'Cadastrar flashcard'}
                      <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'cadastrar'} um flashcard`}</Text>
                    </Flex>
                  </Flex>
                </ModalHeader>
                <ModalBody>
                  <Stack spacing={5} direction='row' mt="10px">
                    <Checkbox 
                      isChecked={type}
                      colorScheme="cyan"
                      value="true" 
                      onChange={() => {
                        setType(true);
                        setErrorPergunta('');
                      }}
                      disabled={!!infoRow?.id}
                    >
                      Modelo Simplificado
                    </Checkbox>
                    <Checkbox 
                      isChecked={!type} 
                      colorScheme="cyan"
                      value="false" 
                      onChange={() => {
                        setType(false);
                        setErrorPergunta('');
                      }}
                      disabled={!!infoRow?.id}
                    >
                      Modelo Completo
                    </Checkbox>
                  </Stack>
                  <Formik
                      initialValues={{
                          attachments: infoRow?.attachments ? JSON.parse(infoRow?.attachments) : [],
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async (values, helpers) => {
                          try {
                              const verifyEditor = handleSave(type ? frontFlashCard : frontFlashCardComplet);
                              if (!verifyEditor) {
                                setErrorPergunta('Preencha alguma informação no campo PERGUNTA');
                                helpers.setStatus({ success: false });
                                helpers.setSubmitting(false);
                                return null;
                              }
                              const response = !infoRow?.id ? await CreateFlashcards(
                                  type ? '1' : '2',
                                  idDeck.id,
                                  idSubDeck.id,
                                  type ? frontFlashCard : frontFlashCardComplet,
                                  type ? backFlashCard : backFlashCardComplet,
                                  type ? undefined : dicaFlashCard,
                                  type ? undefined : comentariosFlashCard,
                                  type ? undefined : esquemaFlashCard,
                                  values.attachments,
                                  undefined,
                                  user?.token,
                              ) : await EditFlashcards(
                                  type ? '1' : '2',
                                  idDeck.id,
                                  idSubDeck.id,
                                  type ? frontFlashCard : frontFlashCardComplet,
                                  type ? backFlashCard : backFlashCardComplet,
                                  type ? undefined : dicaFlashCard,
                                  type ? undefined : comentariosFlashCard,
                                  type ? undefined : esquemaFlashCard,
                                  values.attachments,
                                  infoRow?.id,
                                  user?.token,
                              );
                              if (response?.status === 200 || response?.status === 201) {
                                  helpers.setSubmitting(false);
                                  onClose();
                                  getList();
                              } else {
                                  helpers.setStatus({ success: false });
                                  helpers.setErrors({ submit: response?.message });
                                  helpers.setSubmitting(false);
                              }
                          } catch (err) {
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: err.message });
                              helpers.setSubmitting(false);
                          }
                      }}
                  >
                      {(props) => (
                          <Form style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                  {type ? (
                                    <>
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" display="flex">PERGUNTA <Text color={brandStars}>*</Text>
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Frente
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={frontFlashCard}
                                          onChange={setFrontFlashCard}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                      <Text color="red.500" fontSize='sm' fontWeight='500' ms="4px" mt="4px" display="flex">{errorPergunta}</Text>
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" mt="20px">RESPOSTA
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Verso
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={backFlashCard}
                                          onChange={setBackFlashCard}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" display="flex">PERGUNTA <Text color={brandStars}>*</Text>
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Frente
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={frontFlashCardComplet}
                                          onChange={setFrontFlashCardComplet}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                      <Text color="red.500" fontSize='sm' fontWeight='500' ms="4px" mt="4px" display="flex">{errorPergunta}</Text>
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" mt="20px">RESPOSTA
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Verso
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={backFlashCardComplet}
                                          onChange={setBackFlashCardComplet}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" mt="20px">COMENTÁRIOS
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Verso
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={comentariosFlashCard}
                                          onChange={setComentariosFlashCard}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" mt="20px">
                                        ESQUEMA, MAPA MENTAL OU MNEMÔNICO
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Verso
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={esquemaFlashCard}
                                          onChange={setEsquemaFlashCard}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                      <Text color={textColor} fontSize='sm' fontWeight='500' ms="4px" mb="8px" mt="20px">DICA
                                        <Tag
                                          bg="brand.500"
                                          color="white"
                                          cursor="pointer"
                                          width="auto"
                                          ml="5px"
                                        >
                                          Frente
                                        </Tag>
                                      </Text>
                                      <ReactQuill
                                          value={dicaFlashCard}
                                          onChange={setDicaFlashCard}
                                          modules={modules}
                                          formats={formats}
                                          theme="snow"
                                      />
                                    </>
                                  )}
                                  <Field name='attachments'>
                                      {({ form }) => (
                                          <FormControl alignSelf="center" isInvalid={form.errors.attachments && form.touched.attachments} mt="20px">
                                              <Flex display="flex" flexDirection="column">
                                                  <Dropzone
                                                      minH="60px"
                                                      w="100%"
                                                      borderRadius="10px"
                                                      onImageSelect={(files) => {
                                                          const newArchives = form?.values?.attachments?.length > 0 ? [...form.values.attachments, ...files] : files;
                                                          form.setFieldValue('attachments', newArchives);
                                                      }}
                                                      accept="application/pdf,image/*,audio/*,video/*"
                                                      multiple
                                                      content={
                                                          <Box display="flex" flexDirection="column" gap="10px">
                                                              {form?.values?.attachments?.length > 0 ? (
                                                                  <Text color='main.300' fontSize='xs' fontWeight='400'>{`${form?.values?.attachments?.length} anexos adicionados com sucesso`}</Text>
                                                              ) : (
                                                                  <>
                                                                      <Text color='main.300' fontSize='xs' fontWeight='400'>{`Clique aqui para inserir anexos`}</Text>
                                                                      <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Arquivos aceitos: PDF, imagem, áudio e vídeo</Text>
                                                                  </>
                                                              )}
                                                          </Box>
                                                      }
                                                  />
                                              </Flex>
                                              <FormErrorMessage>{form.errors.attachments}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                              <Grid templateColumns='repeat(6, 1fr)' gap='16px'>
                                  {props?.values?.attachments?.length > 0 ? (
                                      props.values.attachments.map((archive, index) => {
                                          const IconComponent = getFileIcon(archive);
                                          return (
                                              <Flex key={index} padding="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" position="relative" minW='120px' minH='120px' borderRadius="10px" border="1px" borderColor={borderColorArchive} background={bg}>
                                                  <Icon as={IconComponent} w='40px' h='40px' color="main.300" mb="10px" />
                                                  <Text color='secondaryGray.700' fontSize='xs' fontWeight='400' wordBreak="break-all">{getNameWithExtensionFromUrl(archive)}</Text>
                                                  <Icon
                                                      onClick={() => {
                                                          const updatedArchives = props.values.attachments.filter((_, i) => i !== index);
                                                          props.setFieldValue('attachments', updatedArchives);
                                                      }}
                                                      as={MdClose}
                                                      cursor="pointer"
                                                      w='20px'
                                                      h='20px'
                                                      color="red.600"
                                                      position="absolute"
                                                      top="2px"
                                                      right="2px"
                                                      zIndex={2}
                                                  />
                                              </Flex>
                                          );
                                      })
                                  ) : null}
                              </Grid>
                              {props.errors.submit && (
                                  <Text
                                      color={props.status.success ? 'green.500' : 'red.500'}
                                      fontSize='small'
                                      fontWeight='400'
                                      marginTop="20px"
                                  >
                                      {props.errors.submit}
                                  </Text>
                              )}
                              <Button
                                  variant='brand'
                                  isLoading={props.isSubmitting}
                                  type="submit"
                                  alignSelf="flex-end"
                                  w="200px"
                                  marginTop="24px"
                              >
                                  {infoRow?.id ? 'Editar flashcard' : 'Cadastrar flashcard'}
                              </Button>
                          </Form>
                      )}
                  </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb="100px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getHeaderProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {!page?.length || isLoading ? (
            <Flex
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              mt="40px"
              position="absolute"
              left="0px"
              bottom="0px"
              width="100%"
              height="300px"
            >
              {isLoading ? (
                <>
                  <Spinner
                      thickness='5px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color="#0098CC"
                      style={{ width: 75, height: 75 }}
                  />
                  <Text color='secondaryGray.700' mt="20px" fontSize='lg' fontWeight='400'>Carregando dados...</Text>
                </>
              ) : null}
              {isLoading === false && !page?.length ? (
                <Text color='secondaryGray.700' mt="20px" fontSize='lg' fontWeight='400'>Não há dados para serem exibidos.</Text>
              ) : null}
            </Flex>
          ) : page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                key={index} 
                _hover={{
                  backgroundColor: "gray.200",
                }}
              >
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AÇÕES") {
                    return (
                        <Flex align='center' pl="30px" pt="5px">
                            <Menu row={cell.row.original} onClickDuplicate={onClickDuplicate} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                        </Flex>
                    )
                  } else if (cell.column.Header === "NOME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {`#00${cell.row.original.id}`}
                      </Text>
                    );
                  } else if (cell.column.Header === "CRIADO EM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'
                      onClick={() => {
                        onClickEdit(cell.row.original);
                      }}
                      cursor="pointer"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalItems={totalItems}
        />
    </Card>
  );
}
