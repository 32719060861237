import React, { useState } from "react";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdOutlineDeleteForever,
  MdOutlineEdit,
  MdBlock
} from "react-icons/md";
import { CgEye } from "react-icons/cg";
import { IoDuplicate } from "react-icons/io5";

export default function Banner(props) {
  const { ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("main.300", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const [modalConfirm, setModalConfirm] = useState('');

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w='37px'
        h='37px'
        padding="0px 5px 0px 5px"
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
        {...rest}>
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
      </MenuButton>
      <MenuList
        w='150px'
        minW='unset'
        maxW='150px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'>
        {props.onClickView ? (
            <MenuItem
                transition='0.2s linear'
                color={textColor}
                _hover={textHover}
                p='0px'
                borderRadius='8px'
                _active={{
                    bg: "transparent",
                }}
                _focus={{
                    bg: "transparent",
                }}
                mb='10px'
                onClick={() => props.onClickView(props.row)}
            >
                <Flex align='center'>
                    <Icon as={CgEye} h='16px' w='16px' me='8px' />
                    <Text fontSize='sm' fontWeight='400'>
                        Ver dados
                    </Text>
                </Flex>
            </MenuItem>
        ) : null}
        {props.onClickDuplicate ? (
            <MenuItem
                transition='0.2s linear'
                color={textColor}
                _hover={textHover}
                p='0px'
                borderRadius='8px'
                _active={{
                    bg: "transparent",
                }}
                _focus={{
                    bg: "transparent",
                }}
                mb='10px'
                onClick={() => props.onClickDuplicate(props.row)}
            >
                <Flex align='center'>
                    <Icon as={IoDuplicate} h='16px' w='16px' me='8px' />
                    <Text fontSize='sm' fontWeight='400'>
                        Duplicar
                    </Text>
                </Flex>
            </MenuItem>
        ) : null}
        {props.onClickEdit ? (
            <MenuItem
                transition='0.2s linear'
                color={textColor}
                _hover={textHover}
                p='0px'
                borderRadius='8px'
                _active={{
                    bg: "transparent",
                }}
                _focus={{
                    bg: "transparent",
                }}
                mb='10px'
                onClick={() => props.onClickEdit(props.row)}
            >
                <Flex align='center'>
                    <Icon as={MdOutlineEdit} h='16px' w='16px' me='8px' />
                    <Text fontSize='sm' fontWeight='400'>
                        Editar
                    </Text>
                </Flex>
            </MenuItem>
        ) : null}
        {props.onClickBlock ? (
            <MenuItem
                transition='0.2s linear'
                color={textColor}
                _hover={textHover}
                p='0px'
                borderRadius='8px'
                _active={{
                    bg: "transparent",
                }}
                _focus={{
                    bg: "transparent",
                }}
                mb='10px'
                onClick={() => props.onClickBlock(props.row)}
            >
                <Flex align='center'>
                    <Icon as={MdBlock} h='16px' w='16px' me='8px' />
                    <Text fontSize='sm' fontWeight='400'>
                        {props.row.blocked_at ? 'Tornar ativo' : 'Tornar inativo'}
                    </Text>
                </Flex>
            </MenuItem>
        ) : null}
        {props.onClickDelete ? (
            <MenuItem
                transition='0.2s linear'
                p='0px'
                borderRadius='8px'
                color={textColor}
                _hover={textHover}
                _active={{
                    bg: "transparent",
                }}
                _focus={{
                    bg: "transparent",
                }}
                mb='10px'
                onClick={() => {
                  setModalConfirm(true);
                }}
            >
                <Flex align='center'>
                    <Icon as={MdOutlineDeleteForever} h='16px' w='16px' me='8px' />
                    <Text fontSize='sm' fontWeight='400'>
                        Excluir
                    </Text>
                </Flex>
            </MenuItem>
        ) : null}
        {/* <MenuItem
          transition='0.2s linear'
          color={textColor}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}>
          <Flex align='center'>
            <Icon as={MdOutlineSettings} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Panel 4
            </Text>
          </Flex>
        </MenuItem> */}
      </MenuList>
      <Modal isOpen={modalConfirm} onClose={() => setModalConfirm(false)} isCentered scrollBehavior="inside" size="lg">
        <ModalOverlay />
        <ModalContent p="10px">
            <ModalHeader mt="15px">
                Tem certeza que deseja excluir esse item permanentemente?
                <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Clique no botão abaixo para continuar</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column">
              <Button
                w="sm"
                maxW="190px"
                minH="40px"
                alignSelf="flex-end"
                bg='red.400'
                color='white'
                borderRadius="5px"
                style={{ fontWeight: 600, fontSize: 14 }}
                onClick={() => {
                  setModalConfirm(false);
                  props.onClickDelete(props.row);
                }}
            >
              EXCLUIR
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Menu>
  );
}
