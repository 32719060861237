import { api } from './api';

export async function Signin(email, password) {
    try {
        const response = await api.patch('/managers/token', { email, password }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function RefreshToken(refresh) {
    try {
        const response = await api.patch('/managers/refreshToken', { refresh }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function Recover(email) {
    try {
        const response = await api.patch('/managers/recoverPassword', { email }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
