import { api } from './api';

export async function ListFlashcards(idSubdecks, idFlashcard, searchTxt, page, token) {
    try {
        let baseURL = `/flashcards?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idFlashcard) {
            baseURL = `${baseURL}&idFlashcard=${idFlashcard}`
        }
        if (idSubdecks) {
            baseURL = `${baseURL}&idSubdecks=${idSubdecks}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateFlashcards(type, idDecks, idSubdecks, frente, verso, dica, comentarios, esquema, attachments, apkg, token) {
    try {
        const response = await api.post(`/flashcards`, { type, idDecks, idSubdecks, frente, verso, dica, comentarios, esquema, attachments, apkg }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditFlashcards(type, idDecks, idSubdecks, frente, verso, dica, comentarios, esquema, attachments, idFlashcard, token) {
    try {
        const response = await api.put(`/flashcards?idFlashcard=${idFlashcard}`, { type, idDecks, idSubdecks, frente, verso, dica, comentarios, esquema, attachments }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteFlashcards(idFlashcard, token) {
    try {
        const response = await api.delete(`/flashcards?idFlashcard=${idFlashcard}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
