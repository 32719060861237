import { api } from './api';

export async function ListManagers(idManagerLogged, searchTxt, idManager, page, token) {
  try {
      let baseURL = `/managers?limit=10`;
      if (page) {
          baseURL = `${baseURL}&page=${page}`
      }
      if (idManagerLogged) {
          baseURL = `${baseURL}&idManagerLogged=${idManagerLogged}`
      }
      if (idManager) {
          baseURL = `${baseURL}&idManager=${idManager}`
      }
      if (searchTxt) {
          baseURL = `${baseURL}&searchTxt=${searchTxt}`
      }
      const response = await api.get(baseURL, {
          headers: {
              'x-access-token': token,
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}

export async function EditManager(photo, name, email, password, idManager, token) {
    try {
        const response = await api.put(`/managers?idManager=${idManager}`, { photo, name, email, password }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
