import { api } from './api';

export async function ListSubdecks(idDecks, idSubdecks, searchTxt, page, token) {
    try {
        let baseURL = `/subdecks?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idSubdecks) {
            baseURL = `${baseURL}&idSubdecks=${idSubdecks}`
        }
        if (idDecks) {
            baseURL = `${baseURL}&idDecks=${idDecks}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateSubdecks(name, idDecks, idAssuntos, token) {
    try {
        const response = await api.post(`/subdecks`, { name, idDecks, idAssuntos }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditSubdecks(name, idDecks, idAssuntos, idSubdecks, token) {
    try {
        const response = await api.put(`/subdecks?idSubdecks=${idSubdecks}`, { name, idDecks, idAssuntos }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteSubdecks(idSubdecks, token) {
    try {
        const response = await api.delete(`/subdecks?idSubdecks=${idSubdecks}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
