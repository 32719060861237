import { api } from './api';

export async function ListOrgaos(idOrgao, searchTxt, page, token) {
    try {
        let baseURL = `/orgaos?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idOrgao) {
            baseURL = `${baseURL}&idOrgao=${idOrgao}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateOrgaos(name, token) {
    try {
        const response = await api.post(`/orgaos`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditOrgaos(name, idOrgao, token) {
    try {
        const response = await api.put(`/orgaos?idOrgao=${idOrgao}`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteOrgaos(idOrgao, token) {
    try {
        const response = await api.delete(`/orgaos?idOrgao=${idOrgao}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
