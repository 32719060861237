import React from "react";

// Chakra imports
import {
    Box, Flex, Image, useColorModeValue,
    // useColorModeValue
} from "@chakra-ui/react";

// Custom components
// import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import logoWhite from 'assets/img/logoWhite.png';
import logoColor from 'assets/img/logoColor.png';

export function SidebarBrand() {
  //   Chakra color mode
  let logoMode = useColorModeValue(logoColor, logoWhite);

  return (
    <Flex align='center' direction='column'>
      <Box background="transparent" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', width: '150px', padding: '0px 10px 0px 10px' }}>
        <Image h="80px" my="20px" objectFit="contain" src={logoMode} />
      </Box>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
