/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
    Box,
} from "@chakra-ui/react";
import ColumnsTable from "views/admin/sistemas-de-questoes/components/ColumnsTable";
import {
  columnsDataColumns,
} from "views/admin/sistemas-de-questoes/variables/columnsData";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListSistemasDeQuestoes } from "services/sistemasDeQuestoes";

export default function Users() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    const getList = async () => {
        setIsLoading(true);
        const response = await ListSistemasDeQuestoes(null, searchTxt, currentPage, user?.token);
        setIsLoading(false);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
        setTotalItems(response?.result?.amount || 0);
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ColumnsTable
            columnsData={columnsDataColumns}
            tableData={list}
            getList={getList}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchTxt={searchTxt}
            setSearchTxt={setSearchTxt}
            totalItems={totalItems}
            isLoading={isLoading}
        />
    </Box>
  );
}
