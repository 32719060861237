import { api } from './api';

export async function ListAssuntos(idMateria, idAssunto, searchTxt, page, token) {
    try {
        let baseURL = `/assuntos?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idMateria) {
            baseURL = `${baseURL}&idMateria=${idMateria}`
        }
        if (idAssunto) {
            baseURL = `${baseURL}&idAssunto=${idAssunto}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateAssuntos(name, idMaterias, token) {
    try {
        const response = await api.post(`/assuntos`, { name, idMaterias }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditAssuntos(name, idMaterias, idAssunto, token) {
    try {
        const response = await api.put(`/assuntos?idAssunto=${idAssunto}`, { name, idMaterias }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteAssuntos(idAssunto, token) {
    try {
        const response = await api.delete(`/assuntos?idAssunto=${idAssunto}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
