// Chakra imports
import {
//   Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
//   Icon,
  Input,
  InputGroup,
  InputRightElement,
//   InputGroup,
//   InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from 'yup';
// Assets
// import { MdUpload } from "react-icons/md";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { useAuth } from "hooks/use-auth";
import { useAuthContext } from "contexts/SidebarContext";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { EditManager } from "services/managers";
import { ListManagers } from "services/managers";

export default function Upload(props) {
  const { used, total, ...rest } = props;
  const auth = useAuth();
  const { user } = useAuthContext();

  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandStars = useColorModeValue("main.300", "main.200");
    const textColorSecondary = "gray.400";
//   const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
//   const brandColor = useColorModeValue("main.300", "white");
//   const textColorSecondary = "gray.400";
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string(),
    });
  return (
    <Card {...rest} mb='20px' align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        {/* <Dropzone
          w={{ base: "100%", "2xl": "268px" }}
          me='36px'
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          content={
            <Box>
              <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='xl' fontWeight='700' color={brandColor}>
                  Upload Files
                </Text>
              </Flex>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                PNG, JPG and GIF files are allowed
              </Text>
            </Box>
          }
        /> */}
        <Flex direction='column' pe='44px'>
            <Formik
                initialValues={{ name: user?.user?.name || '', email: user?.user?.email || '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={async (values, helpers) => {
                    try {
                        const response = await EditManager(
                            user?.user?.photo,
                            values.name,
                            values.email,
                            values.password,
                            user?.user?.id,
                            user?.token,
                        );
                        if (response?.status === 200 || response?.status === 201) {
                            helpers.setSubmitting(false);
                            const responseList = await ListManagers(null, '', user?.user?.id, 1, user?.token);
                            if (responseList?.result?.list?.length > 0) {
                                await auth.updateDataUser({ ...user, user: { ...responseList.result.list[0] } });
                            } else {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Seus dados foram atualizados mas seu usuário não foi encontrado' });
                                helpers.setSubmitting(false);
                            }
                        } else {
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: response?.message });
                            helpers.setSubmitting(false);
                        }
                    } catch (err) {
                        helpers.setStatus({ success: false });
                        helpers.setErrors({ submit: err.message });
                        helpers.setSubmitting(false);
                    }
                }}
            >
                {(props) => (
                    <Form style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                            <Field name='name'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Nome<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Nome'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='email'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            E-mail<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            type='email'
                                            placeholder='Seu e-mail'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='password' style={{ gridColumn: 'span 2' }}>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.password && form.touched.password}>
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            display='flex'>
                                            Senha<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <Input
                                                {...field}
                                                fontSize='sm'
                                                placeholder='Sua senha'
                                                size='lg'
                                                type={show ? "text" : "password"}
                                                variant='auth'
                                            />
                                            <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: "pointer" }}
                                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                    onClick={handleClick}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Grid>
                        {props.errors.submit && (
                            <Text
                                color={props.status.success ? 'green.500' : 'red.500'}
                                fontSize='small'
                                fontWeight='400'
                                marginTop="20px"
                            >
                                {props.errors.submit}
                            </Text>
                        )}
                        <Button
                            variant='brand'
                            isLoading={props.isSubmitting}
                            type="submit"
                            alignSelf="flex-end"
                            w="150px"
                            marginTop="24px"
                        >
                            Atualizar dados
                        </Button>
                    </Form>
                )}
            </Formik>
        </Flex>
      </Flex>
    </Card>
  );
}
