import { api } from './api';

export async function ListNaturezaDaFonte(idNaturezaDaFonte, searchTxt, page, token) {
    try {
        let baseURL = `/naturezaDaFonte?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idNaturezaDaFonte) {
            baseURL = `${baseURL}&idNaturezaDaFonte=${idNaturezaDaFonte}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateNaturezaDaFonte(name, token) {
    try {
        const response = await api.post(`/naturezaDaFonte`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditNaturezaDaFonte(name, idNaturezaDaFonte, token) {
    try {
        const response = await api.put(`/naturezaDaFonte?idNaturezaDaFonte=${idNaturezaDaFonte}`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteNaturezaDaFonte(idNaturezaDaFonte, token) {
    try {
        const response = await api.delete(`/naturezaDaFonte?idNaturezaDaFonte=${idNaturezaDaFonte}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
