import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaArrowRight } from "react-icons/fa";


export default function NavigationLinks({ links, onClickLink }) {

    return (
        <Flex alignItems="center" gap={2} padding="20px" mb="20px">
            {links.map((link, index) => (
                <React.Fragment key={link}>
                    {index > 0 && <Icon as={FaArrowRight} color="gray.500" fontSize={12} />}
                    <Text
                        cursor={index === links.length - 1 ? 'default' : 'pointer'}
                        color={index === links.length - 1 ? 'main.300' : 'gray.500'}
                        fontWeight={index === links.length - 1 ? 'bold' : 'normal'}
                        onClick={index !== links.length - 1 ? () => onClickLink(link) : undefined}
                        _hover={index !== links.length - 1 ? { color: 'gray.600' } : {}}
                    >
                        {link}
                    </Text>
                </React.Fragment>
            ))}
        </Flex>
    );
}
