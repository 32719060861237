import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdLock,
  MdNote,
  MdAssignment,
  MdCardMembership,
  MdWork,
//   MdDashboard,
} from "react-icons/md";
// import {
//     TbCardsFilled,
// } from "react-icons/tb";
import {
    PiCardsFill,
} from "react-icons/pi";
import {
    SiLevelsdotfyi,
} from "react-icons/si";

// Admin Imports
// import MainDashboard from "views/admin/default";
import Decks from "views/admin/decks";
// import SubDecks from "views/admin/sub-decks";
import Area from "views/admin/area";
import Editais from "views/admin/editais";
// import FlashCards from "views/admin/flash-cards";
import Assuntos from "views/admin/assuntos";
import Profile from "views/admin/profile";
import Materia from "views/admin/materia";
import NaturezaDaFonte from "views/admin/natureza-da-fonte";
import NivelDeFormacao from "views/admin/nivel-de-formacao";
import SistemasDeQuestoes from "views/admin/sistemas-de-questoes";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import forgotPasswordCentered from "views/auth/forgotPassword";
import { FaBook, FaClipboardList, FaFile, FaFileAlt, FaQuestionCircle, FaShapes } from "react-icons/fa";
import Orgaos from "views/admin/orgaos";
import Bancas from "views/admin/bancas";
import Cargos from "views/admin/cargos";
import EstudoPara from "views/admin/estudo-para";

export const formatRoutes = () => {
    const isAuthenticated = window.localStorage.getItem('@plannia_authenticated');

    const routesAuth = [
        {
            name: "Sign In",
            layout: "/auth",
            path: "/sign-in",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: SignInCentered,
            hidden: true,
        },
        {
            name: "Recover Password",
            layout: "/auth",
            path: "/forgot-password",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: forgotPasswordCentered,
            hidden: true,
        },
    ];

    const routesAdm = [
        // {
        //   name: "Dashboard",
        //   layout: "/admin",
        //   path: "/default",
        //   icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
        //   component: MainDashboard,
        // },
        {
            name: "Estudo Para",
            layout: "/admin",
            icon: <Icon as={MdNote} width='20px' height='20px' color='inherit' />,
            path: "/estudo-para",
            component: EstudoPara,
        },
        {
            name: "Área",
            layout: "/admin",
            icon: <Icon as={FaShapes} width='20px' height='20px' color='inherit' />,
            path: "/area",
            component: Area,
        },
        {
            name: "Bancas",
            layout: "/admin",
            icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
            path: "/bancas",
            component: Bancas,
        },
        {
            name: "Órgãos",
            layout: "/admin",
            icon: <Icon as={MdWork} width='20px' height='20px' color='inherit' />,
            path: "/orgaos",
            component: Orgaos,
        },
        {
            name: "Cargos",
            layout: "/admin",
            icon: <Icon as={MdCardMembership} width='20px' height='20px' color='inherit' />,
            path: "/cargos",
            component: Cargos,
        },
        {
            name: "Matéria",
            layout: "/admin",
            icon: <Icon as={FaBook} width='20px' height='20px' color='inherit' />,
            path: "/materia",
            component: Materia,
        },
        {
            name: "Assuntos",
            layout: "/admin",
            icon: <Icon as={FaFileAlt} width='20px' height='20px' color='inherit' />,
            path: "/assuntos",
            component: Assuntos,
        },
        {
            name: "Editais",
            layout: "/admin",
            icon: <Icon as={FaClipboardList} width='20px' height='20px' color='inherit' />,
            path: "/editais",
            component: Editais,
        },
        {
            name: "Natureza da Fonte de Estudo",
            layout: "/admin",
            icon: <Icon as={FaFile} width='20px' height='20px' color='inherit' />,
            path: "/natureza-da-fonte",
            component: NaturezaDaFonte,
        },
        {
            name: "Sistemas de Questões",
            layout: "/admin",
            icon: <Icon as={FaQuestionCircle} width='20px' height='20px' color='inherit' />,
            path: "/sistemas-de-questoes",
            component: SistemasDeQuestoes,
        },
        {
            name: "Nível de Formação",
            layout: "/admin",
            icon: <Icon as={SiLevelsdotfyi} width='20px' height='20px' color='inherit' />,
            path: "/nivel-de-formacao",
            component: NivelDeFormacao,
        },
        {
            name: "Baralhos",
            layout: "/admin",
            icon: <Icon as={PiCardsFill} width='20px' height='20px' color='inherit' />,
            path: "/decks",
            component: Decks,
        },
        // {
        //     name: "Flash Cards",
        //     layout: "/admin",
        //     icon: <Icon as={TbCardsFilled} width='20px' height='20px' color='inherit' />,
        //     path: "/flash-cards",
        //     component: FlashCards,
        // },
        // {
        //     name: "Sub Decks",
        //     layout: "/admin",
        //     icon: <Icon as={FaShapes} width='20px' height='20px' color='inherit' />,
        //     path: "/subDecks",
        //     component: SubDecks,
        // },
        {
          name: "Configurações de perfil",
          layout: "/admin",
          icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
          path: "/profile",
          component: Profile,
          hidden: true,
        },
    ];

    if (isAuthenticated) {
        return [
            ...routesAuth,
            ...routesAdm,
        ];
    }
    
    return routesAuth;
}
