import { api } from './api';

export async function ListMateria(idEdital, idMateria, searchTxt, page, token) {
    try {
        let baseURL = `/materias?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idEdital) {
            baseURL = `${baseURL}&idEdital=${idEdital}`
        }
        if (idMateria) {
            baseURL = `${baseURL}&idMateria=${idMateria}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateMateria(name, token) {
    try {
        const response = await api.post(`/materias`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditMateria(name, idMateria, token) {
    try {
        const response = await api.put(`/materias?idMateria=${idMateria}`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteMateria(idMateria, token) {
    try {
        const response = await api.delete(`/materias?idMateria=${idMateria}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
