import { api } from './api';

export async function ListEstudoPara(idEstudopara, searchTxt, page, token) {
    try {
        let baseURL = `/estudopara?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idEstudopara) {
            baseURL = `${baseURL}&idEstudopara=${idEstudopara}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateEstudoPara(name, token) {
    try {
        const response = await api.post(`/estudopara`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditEstudoPara(name, idEstudopara, token) {
    try {
        const response = await api.put(`/estudopara?idEstudopara=${idEstudopara}`, { name }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteEstudoPara(idEstudopara, token) {
    try {
        const response = await api.delete(`/estudopara?idEstudopara=${idEstudopara}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
