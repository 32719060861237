/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
// import Dropzone from "components/dropzone";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { CreateMateria } from "services/materia";
import { EditMateria } from "services/materia";
import { DeleteMateria } from "services/materia";

export default function ColumnsTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt, totalItems, isLoading } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 10;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandStars = useColorModeValue("main.200", "main.300");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    // const [edital, setEdital] = useState('');
    // const [searchTxtEdital, setSearchTxtEdital] = useState('');
    // const [editais, setEditais] = useState([]);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        // setEdital('');
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
    });

    const onClickEdit = (item) => {
        setInfoRow(item);
        // setEdital(item.id_editais);
        setIsOpen(true);
    };

    const onClickDelete = async (item) => {
        await DeleteMateria(item.id, user?.token);
        getList();
    };

    // useEffect(() => {
    //     const timeOutId = setTimeout(() => {
    //         getListEdital();
    //     }, 500);
    //     return () => clearTimeout(timeOutId);
    // }, [searchTxtEdital]);

    // const getListEdital = async () => {
    //     const response = await ListEditais(null, searchTxtEdital, 1, user?.token);
    //     setEditais(response?.result?.list || []);
    // };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" padding="0px 20px 0px 20px" mb="20px" gap="20px">
          <SearchBar
            placeholder="Buscar matéria"
            value={searchTxt}
            setValue={setSearchTxt}
          />
          <Flex gap="10px" alignItems="center">
            {/* <Dropzone
                minH="60px"
                w="100%"
                borderRadius="10px"
                onImageSelect={(files) => {
                  // files[0] fazer a rota para inclusao em massa a atualizar a listagem
                }}
                styleNone
                accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                content={
                  <Button w='200px' h='44px' variant='brand'>
                    Inclusão em massa
                  </Button>
                }
            /> */}
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
              Cadastrar matéria
            </Button>
          </Flex>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRow ? 'Editar matéria' : 'Cadastrar matéria'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'cadastrar'} um matéria`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={{
                            name: infoRow?.name || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = !infoRow?.id ? await CreateMateria(
                                    values.name,
                                    // edital,
                                    user?.token,
                                ) : await EditMateria(
                                    values.name,
                                    // edital,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' marginBottom="16px">
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Nome<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Nome do matéria'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {/* <FormControl>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Editais
                                        </FormLabel>
                                        <Input
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Buscar edital'
                                            fontWeight='500'
                                            size='lg'
                                            value={searchTxtEdital}
                                            onChange={(txt) => setSearchTxtEdital(txt.target.value)}
                                        />
                                        {editais.map((v) => (
                                            <Tag
                                                bg={edital === v?.id ? 'brand.500' : 'gray'}
                                                color="white"
                                                cursor="pointer"
                                                marginRight="5px"
                                                marginTop="5px"
                                                key={v.id}
                                                onClick={() => setEdital(v.id)}
                                            >
                                                {v.name}
                                            </Tag>
                                        ))}
                                    </FormControl> */}
                                </Grid>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='brand'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    alignSelf="flex-end"
                                    w="200px"
                                    marginTop="24px"
                                >
                                    {infoRow?.id ? 'Editar matéria' : 'Cadastrar matéria'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb="100px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getHeaderProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {!page?.length || isLoading ? (
            <Flex
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              mt="40px"
              position="absolute"
              left="0px"
              bottom="0px"
              width="100%"
              height="300px"
            >
              {isLoading ? (
                <>
                  <Spinner
                      thickness='5px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color="#0098CC"
                      style={{ width: 75, height: 75 }}
                  />
                  <Text color='secondaryGray.700' mt="20px" fontSize='lg' fontWeight='400'>Carregando dados...</Text>
                </>
              ) : null}
              {isLoading === false && !page?.length ? (
                <Text color='secondaryGray.700' mt="20px" fontSize='lg' fontWeight='400'>Não há dados para serem exibidos.</Text>
              ) : null}
            </Flex>
          ) : page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                key={index} 
                _hover={{
                  backgroundColor: "gray.200",
                }}
              >
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AÇÕES") {
                    return (
                        <Flex align='center' pl="30px" pt="5px">
                            <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                        </Flex>
                    )
                  } else if (cell.column.Header === "NOME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "CRIADO EM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'
                      onClick={() => {
                        onClickEdit(cell.row.original);
                      }}
                      cursor="pointer"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalItems={totalItems}
        />
    </Card>
  );
}
