/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    IconButton,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tag,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useEffect, useState } from "react";
import iconeFlashCards from "assets/img/iconeFlashCards.png";

// Custom components
import Card from "components/card/Card";
import { Field, Form, Formik } from "formik";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { DeleteSubdecks } from "services/subdecks";
import { CreateSubdecks } from "services/subdecks";
import { EditSubdecks } from "services/subdecks";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import NavigationLinks from "../../../../components/NavigationLinks";
import { ListAssuntos } from "services/assuntos";

export default function ColumnsTable(props) {
    const { tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt, idDeck, setIdDeck, setIdSubDeck, totalItems, isLoading, setIsLoading } = props;

    const { user } = useAuthContext();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandStars = useColorModeValue("main.200", "main.300");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [searchTxtAssuntos, setSearchTxtAssuntos] = useState('');
    const [assunto, setAssunto] = useState('');
    const [assuntos, setAssuntos] = useState([]);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        setAssunto('');
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
    });

    const onClickView = (item) => {
        setIdSubDeck(item);
        setIsLoading(null);
    };

    const onClickEdit = (item) => {
        setInfoRow(item);
        setAssunto(item.id_assuntos);
        setIsOpen(true);
    };

    const onClickDelete = async (item) => {
        await DeleteSubdecks(item.id, user?.token);
        setInfoRow(null);
        getList();
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListAssuntos();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchTxtAssuntos]);

    const getListAssuntos = async () => {
        const response = await ListAssuntos(null, null, searchTxtAssuntos, 1, user?.token);
        setAssuntos(response?.result?.list || []);
    };

    console.log('idDeck.name', idDeck.name);
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      pb="80px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
        <NavigationLinks
            links={[idDeck.name, 'Sub-baralho']}
            onClickLink={() => {
                setIdDeck(null);
                setIdSubDeck(null);
            }}
        />
        <Flex justifyContent="space-between" flexWrap="wrap" padding="0px 20px 0px 20px" mb="20px" gap="20px">
            <Flex>
                <SearchBar
                    placeholder="Buscar sub-baralho"
                    value={searchTxt}
                    setValue={setSearchTxt}
                />
            </Flex>
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
                Cadastrar sub-baralho
            </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRow ? 'Editar sub-baralho' : 'Cadastrar sub-baralho'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'cadastrar'} um sub-baralho`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={{
                            name: infoRow?.name || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                if (!assunto) {
                                  helpers.setStatus({ success: false });
                                  helpers.setErrors({ submit: 'Selecione um assunto para prosseguir.' });
                                  helpers.setSubmitting(false);
                                  return null;
                                }
                                const response = !infoRow?.id ? await CreateSubdecks(
                                    values.name,
                                    idDeck.id,
                                    assunto,
                                    user?.token,
                                ) : await EditSubdecks(
                                    values.name,
                                    idDeck.id,
                                    assunto,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' marginBottom="16px">
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Nome<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Nome do sub-baralho'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <FormControl>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Assunto(s)<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Buscar assunto'
                                            fontWeight='500'
                                            size='lg'
                                            value={searchTxtAssuntos}
                                            onChange={(txt) => setSearchTxtAssuntos(txt.target.value)}
                                        />
                                        {assuntos.map((v) => (
                                            <Tag
                                                bg={assunto === v?.id ? 'brand.500' : 'gray'}
                                                color="white"
                                                cursor="pointer"
                                                marginRight="5px"
                                                marginTop="5px"
                                                key={v.id}
                                                onClick={() => setAssunto(v.id)}
                                            >
                                                {v.name}
                                            </Tag>
                                        ))}
                                    </FormControl>
                                </Grid>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='brand'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    alignSelf="flex-end"
                                    w="200px"
                                    marginTop="24px"
                                >
                                    {infoRow?.id ? 'Editar sub-baralho' : 'Cadastrar sub-baralho'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Box
            display="flex"
            flexWrap="wrap"
            gap="10px"
            p="10px"
            pt="40px"
            pb="40px"
            // justifyContent="space-between"
        >
            {!tableData?.length || isLoading ? (
            <Flex
              alignItems='center'
              flexDirection='column'
              position="absolute"
              left="0px"
              bottom="0px"
              width="100%"
              height="250px"
            >
              {isLoading ? (
                <>
                  <Spinner
                      thickness='5px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color="#0098CC"
                      style={{ width: 75, height: 75 }}
                  />
                  <Text color='secondaryGray.700' mt="20px" fontSize='lg' fontWeight='400'>Carregando dados...</Text>
                </>
              ) : null}
              {isLoading === false && !tableData?.length ? (
                <Text color='secondaryGray.700' mt="20px" fontSize='lg' fontWeight='400'>Não há dados para serem exibidos.</Text>
              ) : null}
            </Flex>
          ) : tableData.map((subDeck, i) => (
                <Card
                    minW={{ lg: '24%', md: '33%', "2sm": "49%" }}
                    maxW={{ lg: '24%', md: '33%', "2sm": "49%" }}
                    boxShadow="md"
                    flexDirection="row"
                    key={subDeck.id}
                    p="20px"
                    justifyContent="space-between"
                    alignItems="center"
                    border={`1px solid #0090b3`}
                    cursor="pointer"
                    gap={5}
                >
                    <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={() => {
                            onClickView(subDeck)
                        }}
                        height="100%"
                        direction="column"
                    >
                        <Text color="#0090b3" fontSize="sm" fontWeight="bold" textAlign="center">
                            {subDeck.name}
                        </Text>
                        <Flex direction="column" w="100%">
                          <Flex alignItems="flex-end">
                            <Image src={iconeFlashCards} w='20px' h='20px' mr="5px" />
                            <Text color="#0090b3" fontSize="sm" fontWeight="bold" textAlign="center">
                                {`FlashCards: ${subDeck.countFlashcards}`}
                            </Text>
                          </Flex>
                        </Flex>
                    </Flex>
                    <Flex alignItems="flex-end" justifyContent="flex-end" direction="column">
                        <IconButton
                            icon={<FaEye />}
                            variant="ghost"
                            color="#0090b3"
                            fontSize="16px"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickView(subDeck);
                            }}
                        />
                        <IconButton
                            icon={<FaEdit />}
                            variant="ghost"
                            color="#0090b3"
                            fontSize="16px"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickEdit(subDeck);
                            }}
                        />
                        <IconButton
                            icon={<FaTrash />}
                            variant="ghost"
                            color="#0090b3"
                            fontSize="16px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setInfoRow(subDeck);
                              setModalConfirm(true);
                            }}
                        />
                    </Flex>
                    {/* <Text color="#0090b3" fontSize="sm" fontWeight="bold">
                        {subDeck.name}
                    </Text>
                    <Menu row={subDeck} onClickView={onClickView} onClickEdit={onClickEdit} onClickDelete={onClickDelete} /> */}
                </Card>
            ))}
        </Box>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalItems={totalItems}
        />
        <Modal isOpen={modalConfirm} onClose={() => setModalConfirm(false)} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent p="10px">
              <ModalHeader mt="15px">
                  Tem certeza que deseja excluir esse item permanentemente?
                  <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Clique no botão abaixo para continuar</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column">
                <Button
                  w="sm"
                  maxW="190px"
                  minH="40px"
                  alignSelf="flex-end"
                  bg='red.400'
                  color='white'
                  borderRadius="5px"
                  style={{ fontWeight: 600, fontSize: 14 }}
                  onClick={() => {
                    setModalConfirm(false);
                    onClickDelete(infoRow);
                  }}
              >
                EXCLUIR
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      {/* <Table {...getTableProps()} variant='simple' color='gray.500' mb="100px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getHeaderProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AÇÕES") {
                    return (
                        <Flex align='center' pl="30px" pt="5px">
                            <Menu row={cell.row.original} onClickView={onClickView} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                        </Flex>
                    )
                  } else if (cell.column.Header === "NOME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "CRIADO EM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table> */}
        {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        /> */}
    </Card>
  );
}
