/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
    Box,
} from "@chakra-ui/react";
import ColumnsTable from "views/admin/area/components/ColumnsTable";
import {
  columnsDataColumns,
} from "views/admin/area/variables/columnsData";
import ColumnsTableSubArea from "views/admin/area/components/ColumnsTableSubArea";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListArea } from "services/area";
import { ListSubArea } from "services/subArea";

export default function Users() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');

    const [listSubArea, setListSubArea] = useState([]);
    const [totalPagesSubArea, setTotalPagesSubArea] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPageSubArea, setCurrentPageSubArea] = useState(1);
    const [searchTxtSubArea, setSearchTxtSubArea] = useState('');
    const [totalItemsSubArea, setTotalItemsSubArea] = useState('');
    const [isLoading, setIsLoading] = useState(null);

    const [idArea, setIdArea] = useState('');

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    const getList = async () => {
        setIsLoading(true);
        const response = await ListArea(null, searchTxt, currentPage, user?.token);
        setIsLoading(false);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
        setTotalItems(response?.result?.amount || 0);
    };

    useEffect(() => {
        if (idArea) {
            const timeOutId = setTimeout(() => {
                getListSubArea();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPageSubArea, searchTxtSubArea, idArea]);

    const getListSubArea = async () => {
        setIsLoading(true);
        const response = await ListSubArea(idArea, null, searchTxtSubArea, currentPageSubArea, user?.token);
        setIsLoading(false);
        setListSubArea(response?.result?.list || []);
        setTotalPagesSubArea(response?.result?.qtdPages || 1);
        setTotalItemsSubArea(response?.result?.amount || 1);
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {!idArea ? (
            <ColumnsTable
                columnsData={columnsDataColumns}
                tableData={list}
                getList={getList}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchTxt={searchTxt}
                setSearchTxt={setSearchTxt}
                setIdArea={setIdArea}
                totalItems={totalItems}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        ) : null}
        {idArea ? (
            <ColumnsTableSubArea
                columnsData={columnsDataColumns}
                tableData={listSubArea}
                getList={getListSubArea}
                totalPages={totalPagesSubArea}
                currentPage={currentPageSubArea}
                setCurrentPage={setCurrentPageSubArea}
                searchTxt={searchTxtSubArea}
                setSearchTxt={setSearchTxtSubArea}
                idArea={idArea}
                setIdArea={setIdArea}
                totalItems={totalItemsSubArea}
                isLoading={isLoading}
            />
        ) : null}
    </Box>
  );
}
