/* eslint-disable jsx-a11y/alt-text */
// Chakra imports
import {
    Avatar,
    Box,
    // Flex,
    Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useAuthContext } from "contexts/SidebarContext";
import Dropzone from "components/dropzone";
import React from "react";
import { EditManager } from "services/managers";
import { ListManagers } from "services/managers";
import { useAuth } from "hooks/use-auth";

export default function Banner(props) {
  const {
    banner,
    // avatar, name, job, posts, followers, following
} = props;
  const { user } = useAuthContext();
  const auth = useAuth();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
//   const borderColor = useColorModeValue(
//     "white !important",
//     "#111C44 !important"
//   );

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
        <Dropzone
            mx='auto'
            minH='87px'
            w='87px'
            mt='-43px'
            borderRadius='50%'
            onImageSelect={async (file) => {
                const response = await EditManager(file[0], user?.user?.name, user?.user?.email, undefined, user?.user?.id, user?.token);
                if (response?.status === 200 || response?.status === 201) {
                    const responseList = await ListManagers(null, '', user?.user?.id, 1, user?.token);
                    if (responseList?.result?.list?.length > 0) {
                        await auth.updateDataUser({ ...user, user: { ...responseList.result.list[0] } });
                    } else {
                    }
                } else {
                }
            }}
            accept="image/png, image/jpeg, image/jpg"
            content={user?.user?.photo ? (
                <img src={user?.user?.photo} style={{ minWidth: 87, minHeight: 87, borderRadius: '50%', objectFit: 'cover' }} />
            ) :
                <Avatar
                    minH='87px'
                    w='87px'
                    border='4px solid'
                    bg="#11047A"
                    name={user?.user?.name}
                    color={textColorSecondary}
                />
            }
        />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {user?.user?.name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {user?.user?.email}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {user?.user?.type === 'manager' ? 'Master' : 'Operador'}
      </Text>
      {/* <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex> */}
    </Card>
  );
}
