import { api } from './api';

export async function ListDecks(idDeck, searchTxt, page, token) {
    try {
        let baseURL = `/decks?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idDeck) {
            baseURL = `${baseURL}&idDeck=${idDeck}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateDecks(name, idMaterias, idAssuntos, token) {
    try {
        const response = await api.post(`/decks`, { name, idMaterias, idAssuntos }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditDecks(name, idMaterias, idAssuntos, idDeck, token) {
    try {
        const response = await api.put(`/decks?idDeck=${idDeck}`, { name, idMaterias, idAssuntos }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteDecks(idDeck, token) {
    try {
        const response = await api.delete(`/decks?idDeck=${idDeck}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
